<script setup>
import { h } from 'vue';

const props = defineProps({
  htmlText: {
    type: String,
    required: true
  },
  htmlTag: {
    type: String,
    default: 'div'
  },
  htmlClass: {
    type: String,
    default: ''
  }
});
const VNode = () => h(props.htmlTag, { innerHTML: props.htmlText, class: props.htmlClass });
</script>
<template>
  <VNode />
</template>
